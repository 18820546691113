import { render, staticRenderFns } from "./ComponentAdd.vue?vue&type=template&id=ff2c7232&scoped=true&"
import script from "./ComponentAdd.vue?vue&type=script&lang=ts&"
export * from "./ComponentAdd.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff2c7232",
  null
  
)

export default component.exports
import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHeart,
  faHeartBroken,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faArrowRight,
  faArrowLeft,
  faBolt,
  faSlash,
  faMicrochip,
  faUser,
  faRobot,
  faPlug,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";

library.add(
  faHeart,
  regularHeart,
  faHeartBroken,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faArrowRight,
  faArrowLeft,
  faBolt,
  faSlash,
  faMicrochip,
  faUser,
  faRobot,
  faPlug,
  faChartLine
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);

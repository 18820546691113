import gql from "graphql-tag";

export const gridConnectionDetails = gql`
  query GridConnection {
    GridConnection(order_by: { name: asc }) {
      uuid
      name
      description
      max_export_mw
      max_import_mw
      Portfolio {
        name
      }
      Company {
        name
      }
      Assets {
        uuid
      }
    }
  }
`;

import Vue from "vue";
import router from "./router";
import { Auth0Plugin } from "@habitatenergy/lib-frontend";
import App from "./App.vue";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import "./plugins/fontawesome";
import VueClipboard from "vue-clipboard2";
import { createProvider } from "./vue-apollo";

import store from "./store";

Vue.use(VueClipboard);
Vue.config.productionTip = false;

const domain = config.auth0_domain;
const clientId = config.auth0_clientId;
const audience = config.auth0_audience;

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

new Vue({
  router,
  apolloProvider: createProvider(),
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

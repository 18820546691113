
import Vue from "vue";
import { UserInfo } from "@habitatenergy/lib-frontend";

export default Vue.extend({
  name: "NavMenu",
  components: { UserInfo },
  data() {
    return {
      version: process.env.VERSION,
      auth: this.$auth,
    };
  },
  computed: {
    visible: {
      get(): boolean {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit("updateDraw", value);
      },
    },
  },

  methods: {
    logout(): void {
      this.$auth.logout({
        returnTo: `${window.location.origin}/login`,
      });
    },
  },
});

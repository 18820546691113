import gql from "graphql-tag";

export const applicationVersion = gql`
  query applicationVersion {
    Application {
      title
      description
      slug
      uuid
      Components(order_by: { created_utc: desc }, limit: 1) {
        version
      }
    }
  }
`;

export const getAllVersions = gql`
  query GetVersions($appSlug: String) {
    Component(
      where: { Application: { slug: { _eq: $appSlug } } }
      order_by: { created_utc: asc }
    ) {
      version
    }
  }
`;

interface Component {
  version: string;
}

export interface ApplicationRaw {
  title: string;
  description: string;
  slug: string;
  uuid: string;
  Components: Array<Component>;
}

export interface ApplicationFlat {
  title: string;
  description: string;
  slug: string;
  uuid: string;
  latestVersion: string;
}

export function flattenApplicationVersion(
  rows: Array<ApplicationRaw>
): Array<ApplicationFlat> {
  return rows.map(
    (row): ApplicationFlat => {
      let latestVersion: string;
      if (row.Components.length > 0) {
        latestVersion = row.Components[0].version;
      } else {
        latestVersion = "No version";
      }

      let slug = row.slug;
      // Fix optimizer spelling
      if (slug === "optimiser") slug = "optimizer";

      return {
        title: row.title,
        description: row.description,
        slug,
        uuid: row.uuid,
        latestVersion,
      };
    }
  );
}


import Vue from "vue";
import {
  assetApplicationComponents,
  gridConnectionApplicationComponents,
} from "@/graphQL/oo-platform/applicationComponentQueries";
import { ApplicationComponent } from "@/graphQL/oo-platform/applicationComponentInterfaces";
import { DateTime } from "luxon";
import ApplicationLogs from "@/components/apps/ApplicationLogs.vue";
import ComponentReset from "@/components/apps/ComponentReset.vue";
import ComponentUpgrade from "@/components/apps/ComponentUpgrade.vue";
import ComponentDelete from "@/components/apps/ComponentDelete.vue";
import ComponentAdd from "@/components/apps/ComponentAdd.vue";

interface TableRow {
  key: string;
  name: string;
  uuid: string;
  application: string;
  applicationSlug: string;
  version: string;
  instanceID: string;
  updated: string;
}

const headers = [
  { text: "Name", value: "name" },
  {
    text: "Application",
    value: "application",
  },
  {
    text: "Version",
    value: "version",
  },
  {
    text: "Instance ID",
    value: "instanceID",
  },
  {
    text: "Last update",
    value: "updated",
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },
];

function formatDateTime(isoDateTime: string): string {
  const fromDateTime = DateTime.fromISO(isoDateTime);
  return fromDateTime.setZone("local").toFormat("yyyy-LL-dd HH:mm:ss ZZZZ");
}

export default Vue.extend({
  name: "ComponentApplications",
  components: {
    ApplicationLogs,
    ComponentReset,
    ComponentUpgrade,
    ComponentDelete,
    ComponentAdd,
  },
  data: () => ({
    applicationComponents: [] as Array<ApplicationComponent>,
    selectedApplication: undefined,
    selectedName: undefined,
    selected: [],
    headers,
  }),
  props: {
    assetOrGridConnection: {
      required: true,
      type: String,
      validator: (val) => ["Asset", "GridConnection"].includes(val),
    },
  },
  computed: {
    title(): string {
      if (this.assetOrGridConnection === "Asset") {
        return "Asset";
      } else {
        return "Grid Connection";
      }
    },
    rows(): Array<TableRow> {
      if (!this.$apollo.loading) {
        return this.applicationComponents.map(
          (appComponent): TableRow => {
            return {
              key: `${appComponent[this.assetOrGridConnection].uuid}+${
                appComponent.Application.slug
              }`,
              name: appComponent[this.assetOrGridConnection].name,
              uuid: appComponent[this.assetOrGridConnection].uuid,
              application: appComponent.Application.title,
              applicationSlug: appComponent.Application.slug,
              version: appComponent.Component.version,
              instanceID: appComponent.instance_id,
              updated: appComponent.updated_utc,
            };
          }
        );
      } else {
        return [] as Array<TableRow>;
      }
    },
    names(): Array<string> {
      return [...new Set(this.rows.map((row) => row.name))];
    },
    applications(): Array<string> {
      return [...new Set(this.rows.map((row) => row.application))];
    },
    filteredRows(): Array<TableRow> {
      return this.rows.filter((row) => {
        if (
          this.selectedApplication &&
          row.application !== this.selectedApplication
        ) {
          return false;
        } else return !(this.selectedName && row.name !== this.selectedName);
      });
    },
  },
  apollo: {
    $subscribe: {
      applicationComponentAsset: {
        query: assetApplicationComponents,
        result(result) {
          this.applicationComponents = result.data.AssetApplicationComponent;
        },
        skip() {
          return this.assetOrGridConnection !== "Asset";
        },
      },
      applicationComponentGridConnection: {
        query: gridConnectionApplicationComponents,
        result(result) {
          this.applicationComponents =
            result.data.GridConnectionApplicationComponent;
        },
        skip() {
          return this.assetOrGridConnection === "Asset";
        },
      },
    },
  },
  watch: {
    assetOrGridConnection() {
      this.applicationComponents = [] as Array<ApplicationComponent>;
      this.selected = [];
    },
  },
  methods: {
    formatDateTime,
  },
});

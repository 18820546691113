
import Vue from "vue";
import axios from "axios";
import { getAuthHeader } from "@habitatenergy/lib-frontend";

export default Vue.extend({
  name: "NewApplicationVersion",
  data: () => ({
    dialog: false,
    waitingForAPI: false,
    newVersion: "",
    showError: false,
    errorMessage: "",
  }),
  props: {
    applicationName: { type: String, required: true },
    applicationSlug: { type: String, required: true },
    version: { type: String, required: true },
  },
  methods: {
    async callApi() {
      this.waitingForAPI = true;
      this.showError = false;
      const headers = await getAuthHeader(this.$auth);
      const localVue = this;
      const data = {
        version: this.newVersion,
      };
      await axios
        .post(
          `${config.api_root}/platform/applications/${this.applicationSlug}/component/`,
          data,
          { headers }
        )
        .then(function (response) {
          // handle success
          localVue.dialog = false;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          console.log(error.response);
          let details;
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            details = error.response.data.detail;
          } else {
            details = "No details returned";
          }
          localVue.errorMessage = `Error: ${details}`;
          localVue.showError = true;
        })
        .then(function () {
          localVue.waitingForAPI = false;
        });
    },
  },
});

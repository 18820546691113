<template>
  <div>
    <grid-connections></grid-connections>
    <habitat-version-refresh></habitat-version-refresh>
  </div>
</template>

<script>
import GridConnections from "../components/GridConnections";
import { HabitatVersionRefresh } from "@habitatenergy/lib-frontend";
export default {
  name: "home",
  data() {
    return {
      version: process.env.VERSION,
    };
  },
  components: { GridConnections, HabitatVersionRefresh },
};
</script>

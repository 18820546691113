
import Vue from "vue";

const logGroups = {
  optimizer: "platform-optimizer",
  optimiser: "platform-optimizer",
  dispatcher: "site-dispatcher",
  battery_monitor: "monitor",
  battery_emulator: "emulator",
  battery_communication: "communication",
  api: "api-oo-platform",
  events: "events",
  dc_jobs: "dcJobs",
};

export default Vue.extend({
  name: "ApplicationLogs",
  props: {
    applicationSlug: { type: String, required: true },
    instanceID: { type: String },
  },
  methods: {
    goToLogs() {
      if (config.stack_name === "local") {
        const logCommand = `docker logs ${this.instanceID}`;
        this.$copyText(logCommand, this.$refs.copyContainer).then(
          function (e) {
            // console.log(e);
          },
          function (e) {
            console.error("Error copying");
            console.log(e);
          }
        );
      } else {
        let url =
          "https://eu-west-2.console.aws.amazon.com/cloudwatch/home?region=eu-west-2#logsV2:log-groups/log-group/";

        if (this.applicationSlug in logGroups) {
          const logGroup = encodeURIComponent(
            `/ecs/${config.stack_name}/${logGroups[this.applicationSlug]}`
          );
          url += logGroup;
        }
        if (this.instanceID) {
          const filter = `$3FlogStreamNameFilter$3D${this.instanceID}`;
          url += filter;
        }
        window.open(url);
      }
    },
  },
});

import Vue from "vue";
import Vuex from "vuex";
import { DateTime, Duration } from "luxon";
import colors from "vuetify/lib/util/colors";

import { LibTimeFunctions } from "@habitatenergy/lib-frontend";

const { countDownToClosure, calcSP } = LibTimeFunctions;

Vue.use(Vuex);

const OperationalStatus = Object.freeze({
  NORMAL: { colour: colors.green.lighten4 },
  WARNING: { colour: colors.orange.lighten4 },
  ERROR: { colour: colors.red.lighten4 }
});

export default new Vuex.Store({
  state: {
    drawer: false as boolean,
    notifications: true as boolean,
    sound: true as boolean,
    operational_status: OperationalStatus.NORMAL,
    now: DateTime.local().setZone("Europe/London")
  },
  mutations: {
    updateDraw(state, value) {
      state.drawer = value;
    },
    updateTime(state) {
      state.now = DateTime.local().setZone("Europe/London");
    },
    toggleNotifications(state) {
      state.notifications = !state.notifications;
    },
    toggleSound(state) {
      state.sound = !state.sound;
    }
  },
  getters: {
    settlementPeriod(store) {
      const now = store.now;
      return calcSP(now);
    },
    nextSettlementPeriod(store) {
      const minute = Math.floor(store.now.minute / 30.0) * 30.0 + 30.0;
      const endTime = store.now.set({ minute, second: 0, millisecond: 0 });
      return countDownToClosure(store.now, endTime.toObject());
    },
    nextIntradayClosure(store) {
      const minute = Math.floor(store.now.minute / 30.0) * 30.0 + 15.0;
      const endTime = store.now.set({ minute, second: 0, millisecond: 0 });
      return countDownToClosure(store.now, endTime.toObject());
    },
    dAClosure(store) {
      return countDownToClosure(store.now, {
        hour: 11,
        minute: 0,
        second: 0,
        millisecond: 0
      });
    },
    dAHHClosure(store) {
      return countDownToClosure(store.now, {
        hour: 15,
        minute: 30,
        second: 0,
        millisecond: 0
      });
    },
    settlementPeriods(store) {
      const dur = Duration.fromObject({ minutes: 30 });
      const minute = Math.floor(store.now.minute / 30.0) * 30.0;
      const firstSP = store.now.set({ minute, second: 0, millisecond: 0 });
      let currentPeriod = firstSP;
      const periods = [firstSP];
      for (let i = 0; i < 24; i++) {
        currentPeriod = currentPeriod.plus(dur);
        periods.push(currentPeriod);
      }
      return periods;
    }
  },
  actions: {
    startClock({ commit }) {
      setInterval(() => {
        commit("updateTime");
      }, 1000);
    }
  }
});


import Vue from "vue";
import axios from "axios";
import { getAuthHeader } from "@habitatenergy/lib-frontend";
import { getAllVersions } from "@/graphQL/oo-platform/applicationVersionQueries";

export default Vue.extend({
  name: "ComponentUpgrade",
  data: () => ({
    versions: [] as Array<string>,
    dialog: false,
    waitingForAPI: false,
    newVersion: "",
    showError: false,
    errorMessage: "",
  }),
  props: {
    applicationName: { type: String, required: true },
    applicationSlug: { type: String, required: true },
    uuid: { type: String, required: true },
    name: { type: String, required: true },
    version: { type: String, required: true },
    assetOrGridConnection: {
      required: true,
      type: String,
      validator: (val) => ["Asset", "GridConnection"].includes(val),
    },
  },
  computed: {
    assetOrGridURL(): string {
      if (this.assetOrGridConnection === "Asset") {
        return "asset";
      } else {
        return "grid_connection";
      }
    },
  },
  methods: {
    async callApi() {
      this.waitingForAPI = true;
      this.showError = false;
      const headers = await getAuthHeader(this.$auth);
      const localVue = this;
      let application_slug = this.applicationSlug;
      if (application_slug === "optimiser") application_slug = "optimizer";
      const data = {
        version: this.newVersion,
        application_slug,
      };
      await axios
        .put(
          `${config.api_root}/platform/applications/${this.assetOrGridURL}/${this.uuid}`,
          data,
          { headers }
        )
        .then(function (response) {
          // handle success
          localVue.dialog = false;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          console.log(error.response);
          let details;
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            details = error.response.data.detail;
          } else {
            details = "No details returned";
          }
          localVue.errorMessage = `Error: ${details}`;
          localVue.showError = true;
        })
        .then(function () {
          localVue.waitingForAPI = false;
        });
    },
  },
  apollo: {
    versions: {
      query: getAllVersions,
      variables(): object {
        return {
          appSlug: this.applicationSlug,
        };
      },
      update: (data) => {
        return data.Component.map((app) => app.version);
      },
    },
  },
});

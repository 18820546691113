<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar dark flat>
            <v-toolbar-title>Error</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert outlined type="error" prominent border="left">
              404 - Page not found ({{ $route.path }})
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "404"
};
</script>

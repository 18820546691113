
import Vue from "vue";
import BatteryPowerIcon from "./BatteryPowerIcon.vue";
import BatteryCapacityIcon from "./BatteryCapacityIcon.vue";
import colors from "vuetify/lib/util/colors";
import HumanOrRobot from "./HumanOrRobot.vue";
import { batteryQuery } from "@/graphQL/oo-platform/batteryDetailsQueries";
import { BatteryDetailsInterface } from "@/graphQL/oo-platform/batteryDetailsInterface";
import {
  batteryStatus,
  batteryDispatch,
} from "@/graphQL/oo-platform/batteryStatusQueries";
import {
  BatteryPollInterface,
  BatteryDispatchInterface,
} from "@/graphQL/oo-platform/batteryStatusInterface";

export default Vue.extend({
  name: "Battery",
  components: { BatteryCapacityIcon, BatteryPowerIcon },
  data: () => ({
    colors,
    stackName: config.stack_name,
    batteryDetails: {} as BatteryDetailsInterface,
    batteryDispatch: {} as BatteryDispatchInterface,
    batteryStatus: {} as BatteryPollInterface,
  }),
  props: {
    uuid: {
      required: true,
      type: String,
    },
  },
  computed: {
    heart_icon(): String | Array<String> {
      if (
        "heartbeat_value" in this.batteryStatus &&
        this.batteryStatus.heartbeat_value === 1
      ) {
        return "heart";
      } else if (
        "heartbeat_value" in this.batteryStatus &&
        this.batteryStatus.heartbeat_value === 0
      ) {
        return ["far", "heart"];
      } else {
        return "heart-broken";
      }
    },
    grafanaLink(): string {
      return `https://grafana.${this.stackName}.habitatenergy.online/d/OFsOgZonz/battery-overview-1-second-data?orgId=1&refresh=5s&var-battery=${this.batteryDetails.uuid}`;
    },
    broken_heart(): boolean {
      return this.heart_icon === "heart-broken";
    },
    is_dispatch(): boolean {
      return Object.keys(this.batteryDispatch).length > 0;
    },
    is_poll(): boolean {
      return Object.keys(this.batteryStatus).length > 0;
    },
    heart_colour(): object {
      if (this.broken_heart) {
        return { color: "red" };
      } else {
        return { color: "#009688" };
      }
    },
    frequency_hz(): String {
      return (this.batteryStatus && this.batteryStatus.frequency_hz) ? this.batteryStatus.frequency_hz.toFixed(2) : "undefined";
    },
    max_battery_temperature_c(): String {
      return (this.batteryStatus && this.batteryStatus.max_battery_temperature_c) ? this.batteryStatus.max_battery_temperature_c.toFixed(2) : "undefined";
    }
  },
  apollo: {
    $subscribe: {
      batteryObserver: {
        query: batteryQuery,
        variables(): object {
          return {
            uuid: this.uuid,
          };
        },
        result(data) {
          this.batteryDetails = data.data.Asset[0];
        },
      },
      batteryPollObserver: {
        query: batteryStatus,
        variables(): object {
          return {
            uuid: this.uuid,
          };
        },
        result(data) {
          if (data.data.BatteryStatus.length > 0) {
            this.batteryStatus = data.data.BatteryStatus[0];
          } else {
            this.batteryStatus = {} as BatteryPollInterface;
          }
        },
      },
      batteryDispatchObserver: {
        query: batteryDispatch,
        variables(): object {
          return {
            uuid: this.uuid,
          };
        },
        result(data) {
          if (data.data.BatteryDispatchStatus.length > 0) {
            this.batteryDispatch = data.data.BatteryDispatchStatus[0];
          } else {
            this.batteryDispatch = {} as BatteryDispatchInterface;
          }
        },
      },
    },
  },
});

import gql from "graphql-tag";

export const batteryStatus = gql`
  subscription BatteryStatus($uuid: uuid) {
    BatteryStatus(where: { battery_uuid: { _eq: $uuid } }) {
      available_capacity
      available_export_power_mw
      available_import_power_mw
      max_battery_temperature_c
      battery_uuid
      component_uuid
      max_container_temperature_c
      error_message
      frequency_hz
      heartbeat_value
      reported_error
      updated_utc
      power_at_battery_mw
      power_at_meter_mw
      read_utc
      site_mode
      soc
    }
  }
`;

export const batteryDispatch = gql`
  subscription BatteryDispatchStatus($uuid: uuid) {
    BatteryDispatchStatus(where: { battery_uuid: { _eq: $uuid } }) {
      author_uuid
      battery_uuid
      dispatch_message
      dispatch_active_power_mw
      dispatch_uuid
      frequency_contract_uuid
      updated_utc
      site_mode
    }
  }
`;

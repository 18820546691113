
import Vue from "vue";
import axios from "axios";
import { getAuthHeader } from "@habitatenergy/lib-frontend";

export default Vue.extend({
  name: "ComponentDelete",
  data: () => ({
    dialog: false,
    waitingForAPI: false,
  }),
  props: {
    applicationName: { type: String, required: true },
    applicationSlug: { type: String, required: true },
    uuid: { type: String, required: true },
    name: { type: String, required: true },
    assetOrGridConnection: {
      required: true,
      type: String,
      validator: (val) => ["Asset", "GridConnection"].includes(val),
    },
  },
  computed: {
    assetOrGridURL(): string {
      if (this.assetOrGridConnection === "Asset") {
        return "asset";
      } else {
        return "grid_connection";
      }
    },
  },
  methods: {
    async callApi() {
      this.waitingForAPI = true;
      const headers = await getAuthHeader(this.$auth);
      const localVue = this;
      let application_slug = this.applicationSlug;
      if (application_slug === "optimiser") application_slug = "optimizer";
      await axios
        .delete(
          `${config.api_root}/platform/applications/${this.assetOrGridURL}/${this.uuid}/${this.applicationSlug}`,
          { headers }
        )
        .then(function (response) {
          // handle success
          localVue.dialog = false;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          console.log(error.response);
          let details;
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            details = error.response.data.detail;
          } else {
            details = "No details returned";
          }
        })
        .then(function () {
          localVue.waitingForAPI = false;
        });
    },
  },
});


import BatteryPowerIcon from "@/components/BatteryPowerIcon.vue";
import Battery from "./Battery.vue";
import { GridConnectionInterface } from "@/graphQL/oo-platform/gridConnectionInterfaces";
import Vue from "vue";

export default Vue.extend({
  name: "GridConnection",
  props: {
    gridConnection: {
      required: true,
      type: Object as () => GridConnectionInterface,
    },
  },
  data: () => ({
    page: 1,
  }),
  components: { BatteryPowerIcon, Battery: Battery },
});

import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import NotFound from "@/views/404.vue";
import ComponentApplications from "@/views/apps/ComponentApplications.vue";
import ApplicationVersions from "@/views/apps/ApplicationVersions.vue";
import { authGuard } from "@habitatenergy/lib-frontend";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/applications/versions",
      name: "AppVersions",
      component: ApplicationVersions,
    },
    {
      path: "/applications/asset",
      name: "AssetApps",
      component: ComponentApplications,
      props: { assetOrGridConnection: "Asset" },
    },
    {
      path: "/applications/grid-connection",
      name: "GridConnectionApps",
      component: ComponentApplications,
      props: { assetOrGridConnection: "GridConnection" },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/index.html",
      beforeEnter: (to, from, next) => {
        next("/");
      },
    },
    {
      path: "*",
      name: "404",
      component: NotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    return next();
  }
  authGuard(to, from, next);
});

export default router;

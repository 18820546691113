
import Vue from "vue";
import MainAppBar from "./components/MainAppBar.vue";
import NavMenu from "./components/NavMenu.vue";

export default Vue.extend({
  name: "app",
  components: {
    NavMenu,
    MainAppBar,
  },
  methods: {
    async callApi() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
    },
  },
});

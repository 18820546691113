
import Vue from "vue";
import {
  ApplicationFlat,
  flattenApplicationVersion,
} from "@/graphQL/oo-platform/applicationVersionInterface";
import { applicationVersion } from "@/graphQL/oo-platform/applicationVersionQueries";
import ApplicationLogs from "@/components/apps/ApplicationLogs.vue";
import NewApplicationVersion from "@/components/apps/NewApplicationVersion.vue";

export default Vue.extend({
  name: "ApplicationVersions",
  data: () => ({
    applications: [] as Array<ApplicationFlat>,
  }),
  components: {
    ApplicationLogs,
    NewApplicationVersion,
  },
  apollo: {
    applications: {
      query: applicationVersion,
      update(data) {
        return flattenApplicationVersion(data.Application);
      },
    },
  },
});

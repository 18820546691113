import gql from "graphql-tag";

export const batteryQuery = gql`
  subscription batteryDetails($uuid: uuid) {
    Asset(where: { uuid: { _eq: $uuid } }) {
      uuid
      name
      description
      details_json
      physical_json
    }
  }
`;

export const allBatteriesQuery = gql`
  query allBatteries {
    Asset {
      uuid
      name
      description
    }
  }
`;

<template>
  <v-app-bar
    dark
    dense
    app
    :flat="$vuetify.theme.dark"
    :color="$vuetify.theme.dark ? 'undefined' : barColour"
  >
    <router-link :to="{ name: 'Home' }">
      <img
        src="@/assets/trading-ui-logo.svg"
        alt="Habitat logo"
        class="mr-3 mt-1"
        height="40px"
      />
    </router-link>
    <v-badge
      v-if="!!stackName && stackName !== 'metal'"
      color="info"
      :content="stackName"
      left
      bottom
    >
      <v-toolbar-title class="headline">
        {{ title }}
      </v-toolbar-title>
    </v-badge>
    <v-toolbar-title v-else class="headline">
      {{ title }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-app-bar-nav-icon @click="toggleMenu"></v-app-bar-nav-icon>
  </v-app-bar>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "MainAppBar",
  data: () => ({
    largeTitle: "Habitat Energy Mission Control",
    smallTitle: "Mission Control",
    stackName: config.stack_name,
  }),
  computed: {
    ...mapState({
      drawer: (state) => state.drawer,
      operational_status: (state) => state.operational_status,
    }),
    barColour() {
      if (this.stackName === "local") {
        return "blue-grey darken-1";
      } else if (this.stackName === "dev") {
        return "deep-orange darken-4";
      } else if (this.stackName === "next") {
        return "lime darken-3";
      } else {
        return "secondary";
      }
    },
    title() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return this.largeTitle;
      } else {
        return this.smallTitle;
      }
    },
  },
  methods: {
    ...mapMutations(["updateDraw"]),
    toggleMenu() {
      this.updateDraw(!this.drawer);
    },
  },
};
</script>

import gql from "graphql-tag";

export const assetApplicationComponents = gql`
  subscription assetApplicationComponents {
    AssetApplicationComponent {
      Application {
        title
        uuid
        slug
      }
      Asset {
        name
        uuid
      }
      instance_id
      updated_utc
      Component {
        version
        uuid
      }
    }
  }
`;

export const gridConnectionApplicationComponents = gql`
  subscription gridConnectionApplicationComponents {
    GridConnectionApplicationComponent {
      Application {
        title
        uuid
        slug
      }
      GridConnection {
        name
        uuid
      }
      instance_id
      updated_utc
      Component {
        version
        uuid
      }
    }
  }
`;

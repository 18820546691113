<template>
  <font-awesome-icon
    :icon="soc_icon"
    size="lg"
    :style="{ color: colour }"
  ></font-awesome-icon>
</template>

<script>
import colors from "vuetify/lib/util/colors";

export default {
  name: "BatteryCapacityIcon",
  props: {
    soc: {
      required: true,
      type: Number,
    },
    addColours: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    soc_icon() {
      if (this.soc > 90) {
        return "battery-full";
      } else if (this.soc > 60) {
        return "battery-three-quarters";
      } else if (this.soc > 40) {
        return "battery-half";
      } else if (this.soc > 10) {
        return "battery-quarter";
      } else {
        return "battery-empty";
      }
    },
    colour() {
      if (this.addColours) {
        if (this.soc > 90) {
          return colors.teal.base;
        } else if (this.soc > 60) {
          return colors.lime.base;
        } else if (this.soc > 40) {
          return colors.amber.base;
        } else if (this.soc > 10) {
          return colors.deepOrange.base;
        } else {
          return colors.red.base;
        }
      } else {
        return "grey";
      }
    },
  },
};
</script>

<style scoped></style>

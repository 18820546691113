
import Vue from "vue";

export default Vue.extend({
  name: "BatteryPowerIcon",
  props: {
    power: {
      required: true,
      type: Number,
    },
    colour: {
      default: "grey",
      type: String,
    },
    showZero: {
      default: false,
      type: Boolean,
    },
    classSize: {
      default: "fa-lg",
      type: String,
    },
  },
  computed: {
    battery_power() {
      if (this.power < 0) {
        return "arrow-right";
      } else {
        return "arrow-left";
      }
    },
  },
});


import Vue from "vue";
import { LoginView } from "@habitatenergy/lib-frontend";
import colors from "vuetify/lib/util/colors";

export default Vue.extend({
  name: "Login",
  components: { LoginView },
  data: () => ({
    applicationTitle: "Habitat Energy Mission Control",
    toolBarColour: colors.green.lighten4
  }),
  methods: {
    onLogin(): void {
      this.$router.push("/");
    }
  }
});


import Vue from "vue";
import GridConnection from "./GridConnection.vue";
import { gridConnectionDetails } from "@/graphQL/oo-platform/gridConnectionQueries";
import { GridConnectionInterface } from "@/graphQL/oo-platform/gridConnectionInterfaces";

export default Vue.extend({
  name: "GridConnections",
  apollo: {
    gridConnections: {
      query: gridConnectionDetails,
      update: (data) => data.GridConnection,
    },
  },
  data: () => ({
    page: 1,
    gridConnections: [] as Array<GridConnectionInterface>,
  }),
  components: { GridConnection },
  computed: {
    cols(): number {
      if (this.$vuetify.breakpoint.xlOnly) {
        return 3;
      } else if (this.$vuetify.breakpoint.smAndUp) {
        return 2;
      } else {
        return 1;
      }
    },
    itemsByCols(): Array<Array<GridConnectionInterface>> {
      const gridConnectionByCol = [...Array(this.cols)].map(
        () => [] as Array<GridConnectionInterface>
      );

      this.gridConnections.forEach((gridConnection, index) => {
        const col = index % this.cols;
        gridConnectionByCol[col].push(gridConnection);
      });
      return gridConnectionByCol;
    },
  },
});

import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.deepPurple.base,
        secondary: "#004445",
        accent: "#50C9B5",
        error: "#DB2F3A",
        warning: colors.orange.base,
        info: "#5F8ED4",
        success: colors.teal.base,
        stop: colors.grey.darken2,
        charge: "#0014C8",
        discharge: "#CE0101",
        coral: "#ff7f50",
        estopBorder: "#dcdcdc",
      },
      dark: {
        secondary: "#00E888",
        primary: colors.deepPurple.lighten2,
        success: colors.teal.base,
        stop: colors.grey.lighten2,
        charge: colors.blue.lighten2,
        discharge: colors.red.lighten2,
        coral: "#ff7f50",
        estopBorder: "#757575",
        // info: colors.blue.lighten4,
      },
    },
  },
  icons: {
    iconfont: "md",
  },
});

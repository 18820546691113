import Vue from "vue";
import VueApollo from "vue-apollo";
import {
  createApolloClient,
  getAuth0Instance,
  restartWebsockets,
} from "@habitatenergy/lib-frontend";

// Install the vue plugin
Vue.use(VueApollo);

const applicationRoles = [
  "data-access-graphql-global-habitat",
  "data-access-graphql-operations-habitat",
  "data-access-graphql-operator-client",
];

// Call this in the Vue app file
export function createProvider() {
  const auth0 = getAuth0Instance();

  const { apolloClient: platformClient } = createApolloClient({
    clientId: "platform",
    httpEndpoint: config.graphql_platform_http_endpoint,
    wsEndpoint: config.graphql_platform_ws_endpoint,
    auth0,
    applicationRoles,
  });

  // Create vue apollo provider
  return new VueApollo({
    clients: {
      platformClient,
    },
    defaultClient: platformClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "cache-and-network",
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });
}

// Manually call this when user log in
export async function onLogin(apolloClient: any) {
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    // @ts-ignore
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient: any) {
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    // @ts-ignore
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
